.About {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.About-title {
    font-size: 2.8em;
    font-weight: bold;
}

.About-content {
    font-size: 1.2em;
}