.Dashboard {
    width: 70%;
    height: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .Dashboard {
        width: 90%;
    }
}

.Dashboard-header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 50px;
}

.Dashboard-titleFilter {
    flex-grow: 1;
}