.Home {
    display: grid;
    grid-template-rows: auto 1fr;
}

.Home-header {
    background-color: var(--background-dark) !important;
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: "arcade_roundedregular", sans-serif;
    font-weight: bold;
    font-size: 2.5em;
}


@media only screen and (max-width: 600px) {
    .Home-header {
        font-size: 0.8rem;
    }
}

.Home-trailerContainer {
    grid-column: 1;
    grid-row: 2;
    position: relative;
    overflow: hidden;
}

.Home-trailerContainer>video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(15px);
    transform: scale(1.05, 1.05); /* Hides the blur edge offscreen */
}

.Home-content {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 2;
    position: relative;
}

.Home-leftLinkContainer {
    align-self: flex-start;
    margin-top: auto;
    margin-left: 20px;
}

.Home-rightLinkContainer {
    align-self: flex-end;
    margin-bottom: auto;
    margin-right: 20px;
}

.Home-guideLink {
    display: flex;
    gap: 20px;
    color: var(--text-white) !important;
    cursor: pointer;
    text-decoration: none;
    text-shadow: 1px 1px 2px black;
    font-size: min(calc(7vw), 6rem);
}

.Home-guideLink:hover {
    color: var(--text-white-hover) !important;
}

.Home-guideLinkArrowRight {
    transition: transform 0.2s;
}

.Home-guideLink:hover .Home-guideLinkArrowRight {
    transform: translate(20px, 0);
}

.Home-guideLink:active .Home-guideLinkArrowRight {
    transform: translate(400px, 0);
}

.Home-guideLinkLabelRight {
    clip-path: inset(0 0 0 0);
    transition: clip-path 0.2s;
}

.Home-guideLink:active .Home-guideLinkLabelRight {
    clip-path: inset(0 0 0 100%);
}

.Home-guideLinkArrowLeft {
    transition: transform 0.2s;
}

.Home-guideLink:hover .Home-guideLinkArrowLeft {
    transform: translate(-20px, 0);
}

.Home-guideLink:active .Home-guideLinkArrowLeft {
    transform: translate(-550px, 0);
}

.Home-guideLinkLabelLeft {
    clip-path: inset(0 0 0 0);
    transition: clip-path 0.2s;
}

.Home-guideLink:active .Home-guideLinkLabelLeft {
    clip-path: inset(0 100% 0 0);
}