.UserWidget {
    display: flex;
    align-items: center;
}

.UserWidget-currentUser {
    display: flex;
    align-items: center;
    padding-right: 10px;
    gap: 10px;
    cursor: pointer;
}

.UserWidget-userImage {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
}

.UserWidget-userName {
}

.UserWidget-accountInfo {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    border-top: 1px solid var(--border-dark);
    text-wrap: nowrap;
}

.UserWidget-accountInfoProfileLink {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.UserWidget-accountInfoProfileLink:hover {
    filter: brightness(150%);
    background-color: var(--background-dark);
}

.UserWidget-accountInfoLogOutBtn {
    margin: 10px;
}

[hidden]{
    display:none;
}