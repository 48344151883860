.DialogsRoot {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    z-index: 99999;
}

.DialogsRoot-alertStack {
    margin-top: 30px;
    max-width: 50%;
    align-self: center;
}

.DialogsRoot-alert {
    pointer-events: all;
    border-radius: 5px;
    animation: alertFadeIn 0.5s;
}

.DialogsRoot-alert.error {
    animation: alertShakeIn 0.25s;
}

.DialogsRoot-alert.fadeOut {
    animation: alertFadeOut 0.5s;
    animation-fill-mode: forwards;
}

.DialogsRoot-alert.fadeOut.error {
    animation: alertFadeOut 0.5s;
    animation-fill-mode: forwards;
}

@keyframes alertFadeIn {
    0% {
        opacity: 0;
        transform: translate(0, 10px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes alertFadeOut {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, -10px);
    }
}

@keyframes alertShakeIn {
    0% {
        opacity: 0;
        transform: translate(-10px, 0);
    }
    25% {
        opacity: 1;
        transform: translate(10px, 0);
    }
    50% {
        opacity: 1;
        transform: translate(-10px, 0);
    }
    75% {
        opacity: 1;
        transform: translate(10px, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}